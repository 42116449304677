angular.module('fingerink')
    .factory('loginService', ['$http', 'session', '$state', function ($http, session, $state) {
        var sdo = {

            login: function (user, password) {
                var encode = btoa(user + ':' + password);
                return $http({
                    method: 'GET',
                    url: 'login/login/' + encode,
                    crossDomain: true
                });
            },

            registro: function (user) {
                return $http({
                    method: 'POST',
                    url: 'login/register',
                    crossDomain: true,
                    data: user
                });
            },

            registroFinal: function (register) {
                return $http({
                    method: 'POST',
                    url: 'login/registerFinal',
                    crossDomain: true,
                    data: register
                });
            },


            renoveToken: function () {
                return $http({
                    method: 'GET',
                    url: 'login/renoveToken/',
                    crossDomain: true
                });
            },



            existEmail: function (params) {
                return $http({
                    method: 'GET',
                    url: 'login/existEmail' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },
            confirmEmail: function (token) {
                return $http({
                    method: 'GET',
                    url: 'login/confirmEmail/' + token,
                    crossDomain: true
                });
            },
            
            resendConfirmEmail: function () {
                return $http({
                    method: 'POST',
                    url: 'login/resendConfirmEmail',
                    crossDomain: true
                });
            },

            
            
            logout: function () {
                session.clear();
                $state.go('public.login');
            },
            
            passwordRecover: function (email) {
                return $http({
                    method: 'GET',
                    url: 'login/resetPassword?email=' + encodeURI(email),
                    crossDomain: true
                });
            },
            recoverPasswordToken: function (password, token) {
                return $http({
                    method: 'POST',
                    url: 'login/resetPassword/' + token,
                    crossDomain: true,
                    data: {
                        password: btoa(password)
                    }
                });
            }
            
            
        };
        return sdo;
}]);
